import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Logo from "../../Images/logo.svg"
import "./style.css";

function Index() {

    const navigate = useNavigate();

    const [offset, setOffset] = useState<any>(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);

        window.removeEventListener("scroll", onScroll);

        window.addEventListener("scroll", onScroll, { passive: true });

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (


        <div className="landingpagenavbar-main">

            <nav
                className={`nav-dasha w-full h-10 flex items-center pl-3 ${offset <= 750 ? "navbar-top-common" : "navbar-onscroll"}`}>
                <img src={Logo} className='w-48'/>
            </nav>

        </div>


    )
}

export default Index