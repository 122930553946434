import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import LandingNavbar from "../../Layout/LandingNavbar";
import WhatDigiSparkz from "../../Components/LandingComponents/WhatDigiSparkz";
import WhyDigiSparkz from "../../Components/LandingComponents/WhyDigiSparkz";
import ClientReviews from "../../Components/LandingComponents/ClientReviews";
import OurServices from "../../Components/LandingComponents/OurServices";
import LandingFooter from "../../Layout/LandingFooter";

import LandingPicture from "../../Images/landingpic.svg";

function Index() {

    const navigate = useNavigate();
    const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour 
             in place of 'smooth' */
        }); 
      };

      useEffect(() => {
        window.scrollTo(0,0)
      }, [])
      

    return (


        <div className="digisparkz-landingpagemain">

            <div className="landingnavbar-import">
                <LandingNavbar />
            </div>

            <div>
                <div className="digisparkzlandingpage-image w-full h-[140vh]">
                    <div className="grid grid-cols-1 text-start">
                        <div className="grid grid-rows landing mt-52 p-5 pl-20">
                            <div className="font-[600] text-white text-4xl mb-5">
                                Unleash Your Brand's
                            </div>
                            <div className="text-[#642EE4] text-6xl font-semibold mb-5">
                                Creative Potential !
                            </div>
                            <div className="text-white text-lg">
                                With our artistic expertise and innovative approach, <br />
                                we bring your brand to life.
                            </div>
                        </div>
                    </div>
                    <div className="pl-20 mt-10 max-sm:flex max-sm:justify-center max-sm:pr-20">
                        <button onClick={() => scrollToBottom()}
                            className="w-32 h-10 text-[#7939E9] bg-white font-semibold hover:bg-gray-100">
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>

            <div className="whatdigisparkz-import">
                <WhatDigiSparkz />
            </div>

            <div className="whydigisparkz-import">
                <WhyDigiSparkz />
            </div>

            <div className="clientreviews-import">
                <ClientReviews />
            </div>

            <div className="ourservices-import">
                <OurServices />
            </div>

            <div className="landingfooter-import">
                <LandingFooter />
            </div>

        </div>


    )
}

export default Index