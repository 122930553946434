import React from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css";



const root = ReactDOM.createRoot(

  document.getElementById("root") as HTMLElement

);



root.render(<App />);