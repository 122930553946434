import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";
import TrupropsLogo from "../../../Images/Truprops_Logo_black.svg"
import ThumiLogo from "../../../Images/THUMI_LOGO.svg";
import CollaticaLogo from "../../../Images/download.svg"

function Index() {

    const navigate = useNavigate();

    interface Slide {
        id: number;
        content: JSX.Element;
    }

    const slides: Slide[] = [
        {
            id: 1,
            content: (
                <div className="flex flex-col justify-center items-center">
                    <img className="mb-5 h-32 w-32" src={ThumiLogo} alt="Slide 1" />
                    <div className="mb-5">
                        “Working with DigiSparkz was an absolute pleasure! Their team of talented professionals
                        brought our vision to life and exceeded our expectations. The level of creativity and innovation
                        they showcased in our branding and design projects was truly remarkable.”
                    </div>
                    <div className="font-[700]">
                        Mukunthan, Manager
                    </div>
                    <div>
                        Thumi Kitchen
                    </div>
                </div>
            ),
        },
        {
            id: 2,
            content: (
                <div className="flex flex-col justify-center items-center">
                    <img className="mb-5 h-32 w-32" src={TrupropsLogo} alt="Slide 1" />
                    <div className="mb-5">
                        “DigiSparkz, you've redefined creativity! It's like they harnessed a wave of innovation and channeled it into our projects. 
                        The way they reimagined our branding and design concepts was genuinely pioneering and set a new benchmark for ingenuity.”
                    </div>
                    <div className="font-[700]">
                        Thaamarai Kannan, Product Owner
                    </div>
                    <div>
                        Truprops
                    </div>
                </div>
            ),
        },
        {
            id: 3,
            content: (
                <div className="flex flex-col justify-center items-center">
                    <img className="mb-5 h-32 w-32" src={CollaticaLogo} alt="Slide 1" />
                    <div className="mb-5">
                        “It's been a privilege to engage with DigiSparkz. 
                        Their team of seasoned experts meticulously translated our concepts into reality, showcasing a level of professionalism that's hard to come by.”
                    </div>
                    <div className="font-[700]">
                        Abdul Hasib, Manager
                    </div>
                    <div>
                        Collatica
                    </div>
                </div>
            ),
        },
    ];

    const [currentSlide, setCurrentSlide] = useState<Slide>(slides[0]);

    const handleSlideChange = (slide: Slide) => {
        setCurrentSlide(slide);
    };

    return (


        <div>

            <div className="flex flex-col justify-center items-center mb-10 mt-7">
                <div className="text-[#C260FC] text-xl font-[700] mb-2">
                    Our Clients get results
                </div>
                <div className="w-32 border-b-4 border-[#C260FC]"></div>
            </div>

            <div className="dot-slider w-full bg-[#EDC8FB] flex flex-col justify-center items-center p-5">
                <div className="slider-content">
                    {currentSlide.content}
                </div>
                <div className="dot-indicators">
                    {slides.map((slide) => (
                        <span
                            key={slide.id}
                            className={slide.id === currentSlide.id ? 'active' : ''}
                            onClick={() => handleSlideChange(slide)}
                        />
                    ))}
                </div>
            </div>

        </div>


    )
}

export default Index