import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import DigiSparkz from "../../../Images/digilanding.svg"


function Index() {

    const navigate = useNavigate();

    return (


        <div className="whatdigisparkz-main">

            <div className="flex flex-col justify-center items-center mb-10 mt-7">
                <div className="text-[#C260FC] text-xl font-[700] mb-2">
                    What is DigiSparkz
                </div>
                <div className="w-20 border-b-4 border-[#C260FC]"></div>
            </div>

            <div className="flex justify-evenly mb-20 items-center max-md:flex-col max-md:justify-center max-md:gap-10">
                <div>
                    <img src={DigiSparkz} className="" />
                </div>

                <div className="w-96 leading-7">
                    <div className="font-[700]">
                        DIGISPARKZ, where imagination meets innovation!
                    </div>
                    <div>
                        We are a team of passionate professionals dedicated to
                        transforming your ideas into captivating visual experiences.
                        With expertise in design and branding, we specialize in crafting
                        unique solutions that elevate your brand to new heights.
                    </div>
                </div>
            </div>

        </div>


    )
}

export default Index