import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import UIUXImage from "../../../Images/uiux-design.svg";
import PrintImage from "../../../Images/print-design.svg";
import GraphicImage from "../../../Images/graphic-design.svg";
import IllustrationImage from "../../../Images/illustration-design.svg";
import BrandingImage from "../../../Images/branding-design.svg";
import PackagingImage from "../../../Images/packaging-design.svg";
import VideographyImage from "../../../Images/videography-design.svg";
import AnimationImage from "../../../Images/animation-design.svg";

function Index() {

    const navigate = useNavigate();

    return (


        <div className="ourservices-main">

            <div className="flex flex-col justify-center items-center mb-10 mt-7">
                <div className="text-[#C260FC] text-xl font-[700] mb-2">
                    Our Services
                </div>
                <div className="w-14 border-b-4 border-[#C260FC]"></div>
            </div>

            <div className="mb-20">

                <div className="flex justify-evenly mb-10 max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-7">
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={UIUXImage} className='h-10 w-10' />
                            <span className="font-[700]"> UI / UX Design </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Designing user interfaces and user experiences
                                for digital products, such as websites, mobile
                                applications, software interfaces.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={PrintImage} className='h-10 w-10' />
                            <span className="font-[700]"> Print Design </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Designing printed materials such as business
                                cards, flyers, brochures, magazines, banners,
                                and posters.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-evenly mb-10 max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-7">
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={GraphicImage} className='h-10 w-10' />
                            <span className="font-[700]"> Graphic Design </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Creating visual content such as logos, branding
                                materials, advertisements, brochures, posters,
                                and digital graphics.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={IllustrationImage} className='h-10 w-10' />
                            <span className="font-[700]"> Illustration </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Creating hand-drawn or digital illustrations
                                for books, magazines, websites, advertising,
                                packaging, and other visual media.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-evenly mb-10 max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-7">
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={BrandingImage} className='h-10 w-10' />
                            <span className="font-[700]"> Branding and Identity </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Developing brand strategies, logo design, brand
                                guidelines, and visual identity systems to create a
                                consistent and recognizable brand image.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={PackagingImage} className='h-10 w-10' />
                            <span className="font-[700]"> Packaging Design </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Creating visually appealing and functional
                                packaging for products, considering branding,
                                materials, structure, and consumer experience.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-evenly mb-10 max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-7">
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={VideographyImage} className='h-10 w-10' />
                            <span className="font-[700]"> Videography </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Filming and editing videos for various purposes, such
                                as commercials, promotional videos, documentaries,
                                interviews, tutorials, and event coverage.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center space-x-3">
                            <img src={AnimationImage} className='h-10 w-10' />
                            <span className="font-[700]"> Animation </span>
                        </div>
                        <div className="w-72">
                            <p>
                                Designing and producing animated content,
                                including 2D and 3D animations, motion
                                graphics, and animated advertisements.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>


    )
}

export default Index