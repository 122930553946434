import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

function Index() {

    const navigate = useNavigate();

    return (


        <div className="termsandconditions-main">

            Terms & Conditions
            
        </div>


    )
}

export default Index