import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import FacebookIcon from "../../Images/facebook-blackicon.svg";
import TwitterIcon from "../../Images/twitter-blackicon.svg";
import InstagramIcon from "../../Images/instagram-blackicon.svg";
import LinkedinIcon from "../../Images/linkedin-blackicon.svg";
import ContactCall from "../../Images/contact-call.svg";
import ContactMail from "../../Images/contact-mail.svg";

interface ContactProps { }

interface ContactState {
    firstName: string;
    lastName: string;
    emailId: string;
    mobileNumber: string;
    companyName: string;
    firstNameError: string;
    lastNameError: string;
    emailIdError: string;
    mobileNumberError: string;
    companyNameError: string;
}

const Contact: React.FC<ContactProps> = () => {
    const [values, setValues] = useState<ContactState>({
        firstName: "",
        lastName: "",
        emailId: "",
        mobileNumber: "",
        companyName: "",
        firstNameError: "",
        lastNameError: "",
        emailIdError: "",
        mobileNumberError: "",
        companyNameError: "",
    });

    const handleChange =
        (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [name]: event.target.value });
        };

    const handleFirstNameBlur = () => {
        if (values.firstName.length !== 2) {
            setValues({
                ...values,
                firstNameError: "First Name must be 2 digits long",
            });
        } else {
            setValues({ ...values, firstNameError: "" });
        }
    };

    const handleLastNameBlur = () => {
        if (values.lastName.length !== 2) {
            setValues({
                ...values,
                lastNameError: "Last Name must be 2 digits long",
            });
        } else {
            setValues({ ...values, lastNameError: "" });
        }
    };

    const handleEmailIdBlur = () => {
        if (values.emailId.length !== 2) {
            setValues({
                ...values,
                emailIdError: "Email ID must be 2 digits long",
            });
        } else {
            setValues({ ...values, emailIdError: "" });
        }
    };

    const handleMobileBlur = () => {
        if (values.mobileNumber.length !== 10) {
            setValues({
                ...values,
                mobileNumberError: "Mobile Number must be 10 digits long",
            });
        } else {
            setValues({ ...values, mobileNumberError: "" });
        }
    };

    const handleCompanyBlur = () => {
        if (values.companyName.length !== 3) {
            setValues({
                ...values,
                companyNameError: "Company Name must be 3 digits long",
            });
        } else {
            setValues({ ...values, companyNameError: "" });
        }

    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { firstName } = values;
        const { lastName } = values;
        const { emailId } = values;
        const { mobileNumber } = values;
        const { companyName } = values;

        console.log("First Name:", firstName);
        console.log("Last Name:", lastName);
        console.log("Email Id:", emailId);
        console.log("Mobile Number:", mobileNumber);
        console.log("Company Name:", companyName);

        let firstNameError = "";
        let lastNameError = "";
        let emailIdError = "";
        let mobileNumberError = "";
        let companyNameError = "";

        if (!firstName) {
            firstNameError = "First Name is required.";
        } else if (firstName.length !== 2) {
            firstNameError = "First Name must be 2 digits long.";
        }

        if (!lastName) {
            lastNameError = "Last Name is required.";
        } else if (lastName.length !== 2) {
            lastNameError = "Last Name must be 2 digits long.";
        }

        if (!emailId) {
            emailIdError = "Email ID is required.";
        } else if (emailId.length !== 2) {
            emailIdError = "Email ID must be 2 digits long.";
        }

        if (!mobileNumber) {
            mobileNumberError = "Mobile Number is required.";
        } else if (mobileNumber.length !== 10) {
            mobileNumberError = "Mobile Number must be 10 digits long.";
        }

        if (!companyName) {
            companyNameError = "Company Name is required.";
        } else if (companyName.length !== 3) {
            companyNameError = "Company Name must be 3 digits long.";
        }

    };

    const navigate = useNavigate();

    return (


        <div className="contactus-main">

            <div className="p-5 mb-7">
                <div className="text-xl font-semibold text-[#FFFFFA] mb-5">
                    Contact Us - DigiSparkz
                </div>
                <div className="text-lg text-[#FFFFFF]">
                    EMBARK ON YOUR DIGITAL TRANSFORMATION JOURNEY WITH US
                </div>
            </div>

            <div className="flex justify-evenly items-start mb-10 max-md:flex-col max-md:justify-center max-md:items-center max-md:gap-20">

                <div className="bg-[#BDC7F2] h-auto w-2/5 p-5 shadow-md shadow-gray-500 rounded-lg max-md:w-4/5">

                    <div className="text-[#1B1711] font-medium text-lg text-center mb-5">
                        Fill out the form to get your Service.
                    </div>

                    <form onSubmit={handleSubmit}>

                        <div className="flex justify-center gap-5 mb-5">
                            <div className="">
                                <div className="text-[#1C1B1F] text-lg"> First Name </div>
                                <input type="text" value={values.firstName} onChange={handleChange("firstName")} onBlur={handleFirstNameBlur}
                                    className="w-4/5 h-7 outline-none bg-[#F5F5F5] rounded-md indent-2" />
                                {values.firstNameError && (
                                    <div className="text-red-500 font-semibold text-sm">
                                        {values.firstNameError}
                                    </div>
                                )}
                            </div>
                            <div className="">
                                <div className="text-[#1C1B1F] text-lg"> Last Name </div>
                                <input type="text" value={values.lastName} onChange={handleChange("lastName")} onBlur={handleLastNameBlur}
                                    className="w-4/5 h-7 outline-none bg-[#F5F5F5] rounded-md indent-2" />
                                {values.lastNameError && (
                                    <div className="text-red-500 font-semibold text-sm">
                                        {values.lastNameError}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mb-5">
                            <div className="text-[#1C1B1F] text-lg"> Email ID </div>
                            <input type="email" value={values.emailId} onChange={handleChange("emailId")} onBlur={handleEmailIdBlur}
                                className="flex justify-center w-full h-7 outline-none bg-[#F5F5F5] rounded-md indent-2" />
                            {values.emailIdError && (
                                <div className="text-red-500 font-semibold text-sm">
                                    {values.emailIdError}
                                </div>
                            )}
                        </div>

                        <div className="mb-5">
                            <div className="text-[#1C1B1F] text-lg"> Mobile Number </div>
                            <input type="text" value={values.mobileNumber} onChange={handleChange("mobileNumber")} onBlur={handleMobileBlur}
                                className="flex justify-center w-full h-7 outline-none bg-[#F5F5F5] rounded-md indent-2" />
                            {values.mobileNumberError && (
                                <div className="text-red-500 font-semibold text-sm">
                                    {values.mobileNumberError}
                                </div>
                            )}
                        </div>

                        <div className="mb-5">
                            <div className="text-[#1C1B1F] text-lg"> Company / Organization </div>
                            <input type="text" value={values.companyName} onChange={handleChange("companyName")} onBlur={handleCompanyBlur}
                                className="flex justify-center w-full h-7 outline-none bg-[#F5F5F5] rounded-md indent-2" />
                            {values.companyNameError && (
                                <div className="text-red-500 font-semibold text-sm">
                                    {values.companyNameError}
                                </div>
                            )}
                        </div>

                        <div className="mb-5">
                            <div className="text-[#1C1B1F] text-lg"> What services can we provide you? </div>
                            <select className="flex justify-center w-full h-7 outline-none bg-[#F5F5F5] rounded-md indent-2">
                                <option>  </option>
                                <option> UI / UX Design </option>
                                <option> Print Design </option>
                                <option> Graphic Design </option>
                                <option> Illustration </option>
                                <option> Branding & Identity </option>
                                <option> Packaging Design </option>
                                <option> Videography </option>
                                <option> Animation </option>
                            </select>
                        </div>

                        <div className="mb-5">
                            <div className="text-[#1C1B1F] text-lg"> Description </div>
                            <textarea
                                className="flex justify-center w-full h-20 resize-none outline-none bg-[#F5F5F5] rounded-md indent-2"></textarea>
                        </div>

                        <div className="flex justify-center">
                            <button type='submit'
                                className="w-36 h-10 bg-[#1E59D9] text-white font-semibold">
                                Submit
                            </button>
                        </div>

                    </form>

                    <div className="text-sm text-[#000000] mt-5 mb-5 text-center">
                        We will never send you spam or share your email with third parties*
                    </div>

                </div>

                <div className="mb-20">
                    <div className="font-bold text-[#1B1711] text-xl mb-3">
                        For Business Enquiries
                    </div>
                    <div className="mb-7">
                        <div className="flex items-center space-x-3">
                            <img src={ContactCall} className='h-5 w-5' />
                            <span> Toll Free - 17000 - 000 - 0000 </span>
                        </div>
                        <div className="flex items-center space-x-3">
                            <img src={ContactMail} className='h-5 w-5' />
                            <span> Email - help@digisparkz.com </span>
                        </div>
                    </div>
                    <div className="font-bold text-[#1B1711] text-xl mb-3">
                        Get In Touch
                    </div>
                    <div className="">
                        <ul className="col-span-2 flex gap-6 lg:col-span-5">
                            <li>
                                <a href='https://www.facebook.com/' target='_blank'>
                                    <img src={FacebookIcon} height="20px" width="40px" />
                                </a>
                            </li>
                            <li>
                                <a href='https://twitter.com/' target='_blank'>
                                    <img src={TwitterIcon} height="20px" width="40px" />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.instagram.com/' target='_blank'>
                                    <img src={InstagramIcon} height="20px" width="40px" />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.linkedin.com/' target='_blank'>
                                    <img src={LinkedinIcon} height="20px" width="40px" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>


    )
}

export default Contact