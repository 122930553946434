import React from "react";
import RouterComp from "./Router/index";
import './App.css';

function Index() {
  return (
  <>
  <RouterComp/>
  </>
  );
}

export default Index;