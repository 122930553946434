import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

function Index() {

    const navigate = useNavigate();

    return (


        <div className="privacypolicy-main">

            Privacy Policy
            
        </div>


    )
}

export default Index