import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import XBI4WhiteLogo from "../../Images/XBI4 white logo.svg";
import ContactCall from "../../Images/call.svg";
import ContactMail from "../../Images/mail.svg";
import FacebookLogo from "../../Images/facebooklogo.svg";
import TwitterLogo from "../../Images/twitterlogo.svg";
import InstagramLogo from "../../Images/instagramlogo.svg";
import LinkedinLogo from "../../Images/linkedinlogo.svg";

function Index() {

    const navigate = useNavigate();

    return (


        <div className="landingpagefooter-main">

            <footer>

                <div className="w-full bg-[#C260FC] text-[#FFFFFF] p-2">

                    <div className="flex justify-around">
                        {/* <div className="">
                            <div className="font-[600] text-lg mb-5">
                                Quick Links
                            </div>
                            <div className="mb-2">
                                <button onClick={() => navigate("/PrivacyPolicy")}
                                    className="hover:underline">
                                    Privacy Policy
                                </button>
                            </div>
                            <div className="">
                                <button onClick={() => navigate("/TermsandConditions")}
                                    className="hover:underline">
                                    Terms & Conditions
                                </button>
                            </div>
                        </div> */}
                        <div className="">
                            <div className="font-[600] text-lg mb-5">
                                Contact Us
                            </div>
                            <div className="mb-2">
                                <div className="flex items-center space-x-3">
                                    <img src={ContactCall} className='h-5 w-5' />
                                    <span> Mobile: +91 91500 80347 </span>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex items-center space-x-3">
                                    <img src={ContactMail} className='h-5 w-5' />
                                    <span> Email: creatives@digisparkz.com </span>
                                </div>
                            </div>
                        </div>
                        <div className="grid content-center">
                        <div className="font-[600] text-lg mb-5">
                                Connect with us
                            </div>
                            <ul className="col-span-2 flex justify-center gap-6 lg:col-span-5">
                                <li>
                                    <a href='https://www.facebook.com/profile.php?id=100095189093394&mibextid=9R9pXO' target='_blank'>
                                        <img src={FacebookLogo} height="20px" width="35px" />
                                    </a>
                                </li>

                                <li>
                                    <a href='https://twitter.com/DigiSparkz' target='_blank'>
                                        <img src={TwitterLogo} height="20px" width="35px" />
                                    </a>
                                </li>

                                <li>
                                    <a href='https://instagram.com/digi_sparkz?igshid=OGQ5ZDc2ODk2ZA==' target='_blank'>
                                        <img src={InstagramLogo} height="20px" width="35px" />
                                    </a>
                                </li>

                                <li>
                                    <a href='https://www.linkedin.com/company/digi-sparkzxbi4/' target='_blank'>
                                        <img src={LinkedinLogo} height="20px" width="35px" />
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="flex justify-end items-center p-3 gap-3">
                        <div>
                            Powered By
                        </div>
                        <div>
                            <img src={XBI4WhiteLogo} className='w-12'/>
                        </div>
                    </div>

                </div>

            </footer>

        </div>


    )
}

export default Index