import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./style.css";

import Why1Image from "../../../Images/whydigi1.svg";
import Why2Image from "../../../Images/whydigi2.svg";
import Why3Image from "../../../Images/whydigi3.svg";

function Index() {

    const navigate = useNavigate();

    return (


        <div className="whydigisparkz-main">

            <div className="flex flex-col justify-center items-center mb-10 mt-7">
                <div className="text-[#C260FC] text-xl font-[700] mb-2">
                    Why DigiSparkz
                </div>
                <div className="w-24 border-b-4 border-[#C260FC]"></div>
            </div>

            <div className="flex justify-evenly mb-10 max-sm:flex-col max-sm:justify-center max-sm:gap-7">
                <div className="flex flex-col justify-center items-center space-x-3">
                    <img src={Why1Image} className='h-32 w-32' />
                    <span className="font-[700]"> Unleashing Innovation </span>
                </div>
                <div className="flex flex-col justify-center items-center space-x-3">
                    <img src={Why2Image} className='h-32 w-32' />
                    <span className="font-[700]"> Strategic Approach </span>
                </div>
                <div className="flex flex-col justify-center items-center space-x-3">
                    <img src={Why3Image} className='h-32 w-32' />
                    <span className="font-[700]"> Exceptional Customer Service </span>
                </div>
            </div>

            <div className="flex justify-center m-5 text-center mb-20">
                We are a hub of creativity, constantly pushing the boundaries and exploring new possibilities. Our team is driven by a passion for innovation, ensuring
                that every project we undertake is infused with fresh and original ideas. We believe that creativity should be purposeful. Our agency takes a
                strategic approach to every project, aligning our creative solutions with your business goals and target audience. We delve deep into market research
                and competitor analysis to develop strategies that will set you apart and drive measurable results.
            </div>

        </div>


    )
}

export default Index