import React from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import LandingPage from "../Pages/LandingPage";
import PrivacyPolicy from "../Pages/FooterPages/PrivacyPolicy";
import TermsandConditions from "../Pages/FooterPages/TermsandConditions";

import ContactUs from "../Pages/ContactUs";

function Index() {
    return (

        <Router>
            <Routes>

                <Route path="/" element={<LandingPage />} />

                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

                <Route path="/TermsandConditions" element={<TermsandConditions />} />

                <Route path="/ContactUs" element={<ContactUs />} />

            </Routes>
        </Router>

    )
}

export default Index